@import "../../../variables";
@import "../../../mixins";
// @import "../../../helperClasses";

.product-slider {
    @include top-bottom-padding;
    .product-slider-wrapper {
        @include media-breakpoint-down(lg) {
            width: auto;
        }
    }
    h1 {
        @include header(3);
    }
    h3, h1.Title {
        color: $brand-secondary;
        @include bottom-padding;
    }
    .product-download {
        margin-bottom: 10px;
    }
    .small-plp-image-tile {
        // width: 197px;
        // @include media-breakpoint-up(sm) {
        //     width: 136px;
        // }
        // @include media-breakpoint-up(md) {
        //     width: 182px;
        // }
        @include media-breakpoint-up(lg) {
            width: 157px;
        }
        @include media-breakpoint-up(xl) {
            width: 190px;
        }
        @include media-breakpoint-up(xxl) {
            width: 260px;
        }
    }

    .large-plp-image-tile {
        // width: 80%;
        // @include media-breakpoint-only(sm) {
        //     width: 364px;
        // }
        // @include media-breakpoint-up(md) {
        //     width: 244px;
        // }
        @include media-breakpoint-up(lg) {
            width: 314px;
        }
        @include media-breakpoint-up(xl) {
            width: 380px;
        }
        @include media-breakpoint-up(xxl) {
            width: 520px;
        }
    }

    .large-plp-image-tile,
    .small-plp-image-tile {
        .image-container {
            img {
                object-fit: cover;
                height: 200px;

                @include media-breakpoint-up(sm) {
                    height: 190px;
                }
                @include media-breakpoint-up(md) {
                    height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 320px;
                }
            }
        }
        .article-block {
            img {
                object-fit: cover;
                height: 200px;

                @include media-breakpoint-up(sm) {
                    height: 190px;
                }
                @include media-breakpoint-up(md) {
                    height: 188px;
                }
                @include media-breakpoint-up(lg) {
                    height: 228px;
                }
                @include media-breakpoint-up(xl) {
                    height: 282px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 320px;
                }
            }
        }
    }


    .product-tile {
        .tile-footer {
            margin-bottom: 0;

            .pdp-link-btn {
                width: 32px;
                height: 32px;

                img {
                    width: 17px;
                }
            }
        }
    }

    .material-images {
        @include media-breakpoint-up(lg) {
            width: 157px;
        }
        @include media-breakpoint-up(xl) {
            width: 190px;
        }
        @include media-breakpoint-up(xxl) {
            width: 260px;
        }
    }
    .ideastarter-images {
        @include media-breakpoint-up(lg) {
            width: 235.5px;
        }
        @include media-breakpoint-up(xl) {
            width: 285px;
        }
        @include media-breakpoint-up(xxl) {
            width: 390px;
        }
    }

    .document-wrapper {
        padding-right: 0;
        @include media-breakpoint-up(lg) {
            width: 230px;
        }
        @include media-breakpoint-up(xl) {
            width: 280px;
        }
        @include media-breakpoint-up(xxl) {
            width: 385px;
        }
    }
    .content-large-image-tile,
    .content-medium-image-tile {
        .product-download {
            .image-container {
                @include media-breakpoint-up(xl) {
                    width: 50%;
                }
            }
        }
    }
    .article-block.image-with-text {
        padding-top: 0;
        padding-bottom: 0;
        .contentTile-block-link {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            font-size: 0;
        }
        .image-description {
            a {
                position: relative;
                z-index: 2;
            }
        }
    }
    &.custom-text-color {
        div {
            a {
                color: inherit !important;
                span {
                    color: inherit !important;
                }
            }
        }
    }

    /*-- LARGE TILE FOR PRODUCT SLIDER --*/
    .content-large-image-tile {
        width: 80%;
        @include media-breakpoint-only(sm) {
            width: 364px;
        }
        @include media-breakpoint-up(md) {
            width: 244px;
        }
        @include media-breakpoint-up(lg) {
            width: 314px;
        }
        @include media-breakpoint-up(xl) {
            width: 340px;
        }
        @include media-breakpoint-up(xxl) {
            width: 404px;
        }
    }
    /*-- SMALL TILE FOR PRODUCT SLIDER --*/
    .content-small-image-tile {
        width: 197px;
        @include media-breakpoint-up(sm) {
            width: 182px;
        }
        @include media-breakpoint-up(md) {
            width: 122px;
        }
        @include media-breakpoint-up(lg) {
            width: 157px;
        }
        @include media-breakpoint-up(xl) {
            width: 170px;
        }
        @include media-breakpoint-up(xxl) {
            width: 200px;
        }
    }

    .content-large-image-tile,
    .content-small-image-tile {
        .article-block,
        .image-container {
            img {
                object-fit: cover;
                height: 193px;
    
                @include media-breakpoint-up(sm) {
                    height: 221px;
                }
                @include media-breakpoint-up(md) {
                    height: 171px;
                }
                @include media-breakpoint-up(lg) {
                    height: 225px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 230px;
                }
            }
        }
    }  

    /*-- Medium Image Tile--*/

    .content-medium-image-tile {
        width: 293px;
        @include media-breakpoint-up(sm) {
            width: 273px;
        }
        @include media-breakpoint-up(md) {
            width: 247px;
        }
        @include media-breakpoint-up(lg) {
            width: 314px;
        }
        @include media-breakpoint-up(xl) {
            width: 255px;
        }
        @include media-breakpoint-up(xxl) {
            width: 300px;
        }

        .article-block,
        .image-container {
            img {
                object-fit: cover;
                height: 266px;
                
                @include media-breakpoint-up(sm) {
                    height: 240px;
                }
                @include media-breakpoint-up(md) {
                    height: 215px;
                }
                @include media-breakpoint-up(lg) {
                    height: 279px;
                }
                @include media-breakpoint-up(xl) {
                    height: 223px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 270px;
                }
            }
        }
    }

    /*-- Product Downloads --*/
    .content-large-image-tile {
        .product-download {
            @include media-breakpoint-only(md) {
                display: block;
            }
            .image-container {
                @include media-breakpoint-only(md) {
                    width: 80%;       
                }
                @include media-breakpoint-down(md) {
                    width: 50%;       
                }
                @include media-breakpoint-down(sm) {
                    width: 60%;       
                }
                img {
                    object-fit: fill;
                    @include media-breakpoint-only(md) {
                        height: auto;       
                    }
                }
            }
            .product-tile-body {
                @include media-breakpoint-only(md) {
                    width: 100%;       
                }
                @include media-breakpoint-down(md) {
                    width: 50%;       
                }
                @include media-breakpoint-down(sm) {
                    width: 40%;       
                }
            }
        }
    }

    .content-medium-image-tile {
        .product-download {
            @include media-breakpoint-down(lg) {
                display: block;
            }
            .image-container {
                @include media-breakpoint-down(lg) {
                    width: 80%;       
                }
                @include media-breakpoint-down(sm) {
                    width: 70%;       
                }
                img {
                    height: auto;
                    object-fit: fill;
                }
            }
            .product-tile-body {
                @include media-breakpoint-down(lg) {
                    width: 100%;       
                }
            }
        }
    }

    .content-small-image-tile {
        .product-download {
            display: block;
            .image-container {
                width: 100%;
                img {
                    object-fit: contain;
                }
            }
            .product-tile-body {
                width: 100%;       
            }
        }
    }
    .product-plp-wishListButton {
        display: none;
    }
}


